import {
  AuthTypeEnum,
  HospitalInfo,
  ServiceTypeEnum,
  serviceTypeMapper,
  ServiceTypeKeys,
} from '~models/auth.model';
import { USE_UNTACT_AGENT_SET } from '~models/layout.model';

export const toSortedServiceTypeHospitalInfo = (
  hospitalInfo: HospitalInfo,
  authType: string
): HospitalInfo => {
  /**
   * 백엔드에서 타입을 보내주는 경우.
   * 1. 전화상담진료 진료항목이 있는 진료실이 하나라도 있을 경우
   * 2. 전화상담진료 켜진 진료실이 하나라도 있을 경우
   *
   * 진료실 설정에의해 결정 되는 상황이라 서버에서 안넘겨주더라도 조건 체크해서 값 집어넣었습니다.
   */
  const { agentType, serviceType } = hospitalInfo;

  const isUseTelemedicineAgent =
    USE_UNTACT_AGENT_SET.has(agentType) ||
    authType === AuthTypeEnum.DDOCDOC_ADMIN;
  const serviceTypeSet = new Set(serviceType);

  const { TELEMEDICINE } = serviceTypeMapper;

  if (isUseTelemedicineAgent) {
    serviceTypeSet.add(TELEMEDICINE);
  } else {
    serviceTypeSet.delete(TELEMEDICINE);
  }

  return {
    ...hospitalInfo,
    serviceType: Array.from(serviceTypeSet).sort((a, b) => {
      const leftKey = a as ServiceTypeKeys;
      const rightKey = b as ServiceTypeKeys;
      const left = ServiceTypeEnum[leftKey];
      const right = ServiceTypeEnum[rightKey];
      return left - right;
    }),
  };
};
