<div class="container">
  <div class="inner">
    <div class="loading-spinner">
      <img
        alt="loading"
        src="/assets/img/icons/icon-spinner@2x.png"
        [width]="48"
        [height]="48"
      />
    </div>
    <p class="description">잠시만 기다려주세요.</p>
  </div>
</div>
