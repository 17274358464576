import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonFooterComponent implements OnInit {
  @Input() showConfirm = true;
  @Input() confirmText = '확인';
  @Input() cancelText = '취소';
  @Input() hasCancel = true;
  @Input() disabledConfirm = false;

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleConfirm() {
    this.confirm.emit();
  }

  handleCancel() {
    this.cancel.emit();
  }
}
