import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export interface AuthInfoState {
  authInfo: fromAuth.AuthState;
}

export const reducers: ActionReducerMap<AuthInfoState> = {
  authInfo: fromAuth.reducer,
};

export const selectAuthState = createFeatureSelector<AuthInfoState>('auth');

export const getAuthInfoState = createSelector(
  selectAuthState,
  (state: AuthInfoState) => state.authInfo
);

// auth state
export const getAccountInfoState = createSelector(
  getAuthInfoState,
  fromAuth.getAccountInfo
);

export const getGuideType = createSelector(
  getAuthInfoState,
  fromAuth.getGuideType
);

export const getHospitalTitle = createSelector(
  getAccountInfoState,
  state => state?.hospital?.title
);

export const getAuthLoading = createSelector(
  getAuthInfoState,
  fromAuth.getAuthLoading
);

export const getLoggedIn = createSelector(
  getAuthInfoState,
  fromAuth.getLoggedIn
);
