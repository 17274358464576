import { AccountInfo } from '~models/auth.model';
import { Observable } from 'rxjs';
import { Api } from './../http-api';
import { Injectable } from '@angular/core';

// let seq = 0;

@Injectable()
export class AuthService {
  constructor(private api: Api) {}

  /**
   * 계정 정보 불러오기 : Token
   * @memberOf AuthService
   */
  fetchUserInfo(): Observable<AccountInfo> {
    // if (seq++ > 5) {
    //   throw new Error('아니 뭘 이렇게 자꾸 호출햇!');
    // }
    const url = 'api/v1/account/me';
    return this.api.get(url);
  }
}
