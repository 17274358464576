import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { map, take, zipAll } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromAuth from '~reducers/auth';
import { AuthActions } from '~actions/auth/auth.action';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromAuth.AuthInfoState>
  ) {
    of(
      this.activatedRoute.queryParamMap.pipe(
        map(paramMap => paramMap.get('token'))
      ),
      this.activatedRoute.fragment
    )
      .pipe(zipAll(), take(1))
      .subscribe(([token, path]) => {
        this.store.dispatch(
          AuthActions.LoginAsIframe({
            payload: {
              token,
              path,
            },
          })
        );
      });
  }

  ngOnInit(): void {}
}
