import { AccountInfo, LoginSuccessInfo } from '~models/auth.model';
import { createAction, props } from '@ngrx/store';

export const AuthActionTypes = {
  LOGIN: '[Auth] Login',
  LOGIN_AS_IFRAME: '[Auth] Login As Iframe',
  LOGIN_AS_IFRAME_SUCCESS: '[Auth] Login As Iframe Success',
  LOGIN_SUCCESS: '[Auth] Login Success',
  LOGIN_COMPLETE: '[Auth] Login Complete',
  LOGIN_FAILURE: '[Auth] Login Failure',
  LOGIN_REDIRECT: '[Auth] Login Redirect',
  LOGOUT: '[AUTH] Logout',
  ACCOUNT_FETCH: '[Auth] Account Fetch',
  ACCOUNT_FETCH_COMPLETE: '[Auth] Account Fetch Complete',
  ACCOUNT_FETCH_FAILURE: '[Auth] Account Fetch Failure',
  ACCOUNT_EXPIRED: '[Auth] Account Expired',
} as const;

export const LoginAsIframe = createAction(
  AuthActionTypes.LOGIN_AS_IFRAME,
  props<{
    payload: {
      token: string;
      path: string;
    };
  }>()
);

export const LoginAsIframeSuccess = createAction(
  AuthActionTypes.LOGIN_AS_IFRAME_SUCCESS,
  props<{
    payload: {
      path: string;
    };
  }>()
);

export const LoginSuccess = createAction(
  AuthActionTypes.LOGIN_SUCCESS,
  props<{ payload: LoginSuccessInfo }>()
);

export const LoginComplete = createAction(AuthActionTypes.LOGIN_COMPLETE);

export const LoginFailure = createAction(
  AuthActionTypes.LOGIN_FAILURE,
  props<{ payload: any }>()
);

export const LoginRedirect = createAction(AuthActionTypes.LOGIN_REDIRECT);

export const Logout = createAction(AuthActionTypes.LOGOUT);

// 계정 정보 조회
export const AccountFetch = createAction(
  AuthActionTypes.ACCOUNT_FETCH,
  props<{ payload?: LoginSuccessInfo }>()
);

export const AccountFetchComplete = createAction(
  AuthActionTypes.ACCOUNT_FETCH_COMPLETE,
  props<{ payload: AccountInfo }>()
);

export const AccountFetchFailure = createAction(
  AuthActionTypes.ACCOUNT_FETCH_FAILURE,
  props<{ payload: any }>()
);

export const AccountExpired = createAction(
  AuthActionTypes.ACCOUNT_EXPIRED,
  props<{ payload?: any }>()
);

export const AuthActions = {
  LoginAsIframe,
  LoginAsIframeSuccess,
  LoginSuccess,
  LoginComplete,
  LoginFailure,
  AccountFetch,
  AccountFetchComplete,
  AccountFetchFailure,
  AccountExpired,
  LoginRedirect,
  Logout,
};
