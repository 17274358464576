import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor() {}

  create(name: string, value: any, days?: number) {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }

    document.cookie = name + '=' + value + expires + '; path=/';
  }

  createUntilToday(name: string, value: any) {
    const today = moment();
    const expires = '; expires=' + today.endOf('day').toDate().toUTCString();
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  read(name: string): string | null {
    const nameEquals = name + '=';
    const cookiesArray = document.cookie.split(';');

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }

      if (cookie.indexOf(nameEquals) === 0) {
        return cookie.substring(nameEquals.length, cookie.length);
      }
    }

    return null;
  }

  erase(name: string) {
    this.create(name, '', -1);
  }
}
