import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ContainerFooterComponent } from './components/container-footer/container-footer.component';
import { ContainerHeaderComponent } from './components/container-header/container-header.component';
import { CopyTabListComponent } from './components/copy-tab-list/copy-tab-list.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LimitedTextareaComponent } from './components/limited-textarea/limited-textarea.component';
import { ModalBodyComponent } from './components/modal-body/modal-body.component';
import { PageGuideComponent } from './components/page-guide/page-guide.component';
import { RadioComponent } from './components/radio/radio.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TabListComponent } from './components/tab-list/tab-list.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { WeekdaySelectorComponent } from './components/weekday-selector/weekday-selector.component';
import { FileDropDirective } from './directives/file.drop.directive';
import { SliderItemDirective } from './directives/slider-item.directive';
import { YnumAutoHyphenDirective } from './directives/ynum-auto-hyphen.directive';
import { PhoneNumAutoHyphenDirective } from './directives/phone-num-auto-hyphen.directive';
import { IconCalendarComponent } from './icons/icon-calendar.component';
import { IconInfoComponent } from './icons/icon-info.component';
import { ApmFormat } from './pipe/apm-format.pipe';
import { DayTransForm } from './pipe/dayTrans.pipe';
import { DualTimeFormatPipe } from './pipe/dual-time-format.pipe';
import { MomentFormatPipe } from './pipe/moment-format.pipe';
import { Nl2br } from './pipe/nl2br.pipe';
import { ObjectNgFor } from './pipe/obj-ngfor.pipe';
import { PhoneHyphen } from './pipe/phoneFormat.pipe';
import { ResPaperNamePipe } from './pipe/prescriptionPrintingPaper.pipe';
import { ResCellColorPipe } from './pipe/resCellColor.pipe';
import { ResScheduleColorPipe } from './pipe/resScheduleColor.pipe';
import { ResStateClassPipe } from './pipe/resStateClass.pipe';
import { ResStateClassListPipe } from './pipe/resStateClassList.pipe';
import { ResStateNamePipe } from './pipe/resStateName.pipe';
import { ResStateNameListPipe } from './pipe/resStateNameList.pipe';
import { TimeRangeFormatPipe } from './pipe/timeRangeFormat.pipe';
import { TruncateCharactersPipe } from './pipe/truncateCharacter.pipe';
import { WeekNamePipe } from './pipe/weekName.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { CookieService } from './service/cookie.service';
import { ImageService } from './service/image.service';
import { SnsShareService } from './service/sns-share.service';
import { StorageService } from './service/storage.service';
import { ValidatorService } from './service/validator.service';
import { InputTextComponent } from './components/input-text/input-text.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { CregnoAutoHyphenDirective } from './directives/cregno-auto-hyphen.directive';
import { CardGuideComponent } from './components/card-guide/card-guide.component';
import { LabelListedSelectComponent } from './components/label-listed-select/label-listed-select.component';
import { ListedSelectComponent } from './components/listed-select/listed-select.component';
import { BoldTextPipe } from './pipe/bold-text.pipe';
import { EmphasisColorTextPipe } from './pipe/emphasis-color-text.pipe';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { ClickOutSideDirective } from './directives/click-outside-of-target.directive';
import { CommonFooterComponent } from './components/common-footer/common-footer.component';
import { NumberCommaPipe } from './pipe/number-comma.pipe';
import { DecodeUnitKeyPipe } from './pipe/decode-unitKey.pipe';
import { DayOfWeekKorPipe } from './pipe/day-of-week-kor.pipe';
import { DataCyDirective } from './directives/data-cy.directive';
import { AutoHyphenPhoneNumberPipe } from './pipe/auto-hyphen-phone-number.pipe';
import { BankNamePipe } from './pipe/bank-name.pipe';
import { TossErrorPipe } from './pipe/toss-error.pipe';
import { ChildScreeningTooltipPipe } from '~shared/pipe/child-screening-tooltip.pipe';

const components = [
  DayTransForm,
  ApmFormat,
  ObjectNgFor,
  Nl2br,

  TruncateCharactersPipe,
  MomentFormatPipe,
  WeekNamePipe,
  ResStateNameListPipe,
  ResStateNamePipe,
  ResStateClassPipe,
  ResStateClassListPipe,
  ResScheduleColorPipe,
  ResCellColorPipe,
  ResPaperNamePipe,
  ResStateNamePipe,
  ResStateClassPipe,
  TimeRangeFormatPipe,
  DualTimeFormatPipe,
  SafeUrlPipe,
  BoldTextPipe,
  EmphasisColorTextPipe,
  BankNamePipe,
  TossErrorPipe,
  ChildScreeningTooltipPipe,

  PhoneHyphen,
  ContainerHeaderComponent,
  ContainerFooterComponent,
  CommonFooterComponent,
  DropdownComponent,
  PageGuideComponent,
  IconInfoComponent,
  IconCalendarComponent,
  SpinnerComponent,
  TabListComponent,
  CopyTabListComponent,
  ToggleSwitchComponent,
  LimitedTextareaComponent,

  ModalBodyComponent,
  WeekdaySelectorComponent,

  SliderItemDirective,
  FileDropDirective,
  YnumAutoHyphenDirective,
  PhoneNumAutoHyphenDirective,
  NumberOnlyDirective,
  CregnoAutoHyphenDirective,
  ClickOutSideDirective,
  DataCyDirective,

  InputTextComponent,
  RadioComponent,

  // card component
  CardGuideComponent,

  // listed Select component
  LabelListedSelectComponent,
  ListedSelectComponent,
];

const entry = [RadioComponent];

@NgModule({
  imports: [
    NgbModule,
    RouterModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    ...components,
    SanitizeHtmlPipe,
    PageTitleComponent,
    NumberCommaPipe,
    DecodeUnitKeyPipe,
    DayOfWeekKorPipe,
    AutoHyphenPhoneNumberPipe,
  ],
  entryComponents: entry,
  exports: [
    ...components,
    NgbModule,
    RouterModule,
    BsDatepickerModule,
    SanitizeHtmlPipe,
    PageTitleComponent,
    NumberCommaPipe,
    DecodeUnitKeyPipe,
    DayOfWeekKorPipe,
    AutoHyphenPhoneNumberPipe,
    BankNamePipe,
    TossErrorPipe,
  ],
  providers: [
    CookieService,
    StorageService,
    SnsShareService,
    ImageService,
    ValidatorService,
  ],
})
export class SharedModule {}
