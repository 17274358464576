import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SortablejsModule } from 'ngx-sortablejs';

import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { metaReducers, reducers } from './app.reducer';
import { AppRoutes } from './app.routing';
import { AuthModule } from './modules/auth/auth.module';
import { CustomRouterStateSerializer } from '~core/custom-router-state-serializer';
import { Api } from '~core/http-api';
import { RouterEffects } from '~core/router.effect';
import { TokenInterceptor } from '~core/token.interceptor';
import { TokenProvider } from '~core/token.provider';
import { AlertModalComponent } from '~shared/components/alert-modal/alert.modal.component';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { StorageService } from '~shared/service/storage.service';
import { SharedModule } from '~shared/shared.module';
import { SharedNewModule } from '~shared-new/shared-new.module';

@NgModule({
  declarations: [AppComponent, AlertModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    SharedNewModule,
    AppRoutes,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    EffectsModule.forRoot([RouterEffects]),
    StoreDevtoolsModule.instrument({
      name: '똑닥 병원 어드민',
      logOnly: environment.production,
      maxAge: 5,
    }),
    AuthModule,
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    Api,
    StorageService,
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
    SimpleModalService,
    TokenProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
