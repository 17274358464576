import { Injectable } from '@angular/core';
import { iif, merge, Observable, Subject, timer, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import * as jose from 'jose';
import * as moment from 'moment';

declare var Cookies: any;

const TOKEN_KEY = 'ddocdoc_token';

@Injectable({
  providedIn: 'root',
})
export class TokenProvider {
  private token: string | null;
  private sbjExist = new Subject<boolean>();
  public tokenRefreshHandler: Subject<string>;

  constructor() {
    if (!this.tokenRefreshHandler) {
      this.tokenRefreshHandler = new Subject<string>();
    }
    this.token = Cookies.get(TOKEN_KEY);

    window.addEventListener('message', event => {
      const messageEvent = event as MessageEvent<{
        type: 'SendRefreshedToken';
        token: string;
      }>;

      switch (messageEvent.data.type) {
        /**
         * @description Next.js 병드민 에서 보내는 갱신된 토큰을 받아서 처리합니다.
         */
        case 'SendRefreshedToken': {
          this.tokenRefreshHandler.next(messageEvent.data.token);
          this.set(messageEvent.data.token);
          break;
        }
      }
    });
  }

  set(val: string) {
    Cookies.set(TOKEN_KEY, val, { expires: 86400 });
    this.token = val;
    this.sbjExist.next(!!val);
  }

  setByHours(val: string, hours: number) {
    Cookies.set(TOKEN_KEY, val, {
      expires: new Date(new Date().getTime() + 60 * 60 * 1000 * hours),
    });
    this.token = val;
    this.sbjExist.next(!!val);
  }

  get() {
    return this.token;
  }

  getWithAutoRefresh(isForce = false): Observable<string> {
    const currentToken = this.get();
    return iif(
      () => {
        const NOW = moment();
        const decodedJWT = jose.decodeJwt(currentToken);
        const tokenExipreDate = moment.unix(decodedJWT.exp);

        if (tokenExipreDate.isAfter(NOW) && !isForce) {
          return true;
        } else {
          window.parent.postMessage(
            {
              type: 'RequestTokenRefresh',
            },
            '*'
          );
          return false;
        }
      },
      of(currentToken),
      this.tokenRefreshHandler.pipe(delay(500))
    );
  }

  exist(): Observable<boolean> {
    if (this.token) {
      return new Observable(sub => {
        sub.next(true);
        sub.complete();
      });
    }
    return merge(timer(1000), this.sbjExist).pipe(
      map<any, any>(([timing, isExistSbj]) => {
        if (!isExistSbj) {
          throw new Error('token getting timeout');
        }

        return isExistSbj;
      })
    );
  }

  clear() {
    Cookies.remove(TOKEN_KEY);
    this.token = null;
    // this.sbjExist.next(false);
  }
}
