import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthComponentsModule } from './components/auth.components.module';
import { SharedModule } from '~shared/shared.module';

import { AuthService } from '~core/api/auth.service';

import { AuthEffects } from '~effects/auth/auth.effects';

import { reducers } from '~reducers/auth';

import { AuthComponent } from './pages/auth/auth.component';
import { ErrorComponent } from '~root/app/modules/auth/pages/error/error.component';
import { AuthParamGuard } from '~root/app/modules/auth/pages/auth/auth-param.guard';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    AuthComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
      },
      {
        path: 'auth',
        component: AuthComponent,
        canActivate: [AuthParamGuard],
      },
      { path: 'error', component: ErrorComponent },
    ]),
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),
  ],
  declarations: [AuthComponent, ErrorComponent],
  providers: [AuthService],
})
export class AuthModule {}
