<div class="container-footer d-flex">
  <div class="content-wrap flex-grow-1 d-flex align-items-center">
    <ng-content></ng-content>
  </div>
  <div class="btn-wrap flex-shrink-1 text-right">
    <button
      *ngIf="enableCancelCustom && !cancel"
      class="ha-btn ha-btn-secondary"
      (click)="cancelCustomEvent.emit()"
    >
      {{ cancelText }}
    </button>
    <button
      *ngIf="!!options.cancelBtn && cancelForIframe && !enableCancelCustom"
      class="ha-btn ha-btn-secondary"
      (click)="onIframeCancel()"
    >
      {{ cancelText }}
    </button>
    <button
      *ngIf="!!options.cancelBtn && !cancelForIframe && !enableCancelCustom"
      [routerLink]="cancelBtn"
      class="ha-btn ha-btn-secondary"
    >
      {{ cancelText }}
    </button>
    <button
      #popover="ngbPopover"
      (click)="onSubmit()"
      *ngIf="showConfirm"
      [disabled]="options.disabled"
      [ngbPopover]="careRoomInitPopover"
      autoClose="inside"
      class="btn ha-btn ha-btn-spec"
      placement="top"
      triggers="manual"
    >
      {{ confirmText }}
    </button>
    <ng-template #careRoomInitPopover>
      <div
        class="ha-popover-content popover-text"
        style="padding: 32px 24px; text-align: center"
      >
        <p>
          <span style="font-size: 16px; font-weight: 300"
            >기본 설정된 접수시간을 확인(수정) 후</span
          >
          <br />
          <span style="font-size: 16px">저장하기 버튼을 클릭해주세요.</span>
        </p>
      </div>
    </ng-template>
  </div>
</div>
