import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { set as _set } from 'lodash';
import { of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { getErrorMessage } from '~shared/service/util';
import { TokenProvider } from './token.provider';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private tokenProvider: TokenProvider,
    private simpleModalService: SimpleModalService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.tokenProvider.getWithAutoRefresh().pipe(
      take(1),
      switchMap(token => {
        let header = {
          Authorization: `Bearer ${token}`,
        };

        if (req.body instanceof FormData) {
          // header['Content-Type'] = 'multipart/form-data';
          // header['Content-Type'] = 'application/x-www-form-urlencoded';
        } else {
          header = _set(header, 'Content-Type', 'application/json');
        }

        req = req.clone({
          setHeaders: header,
        });
        return next.handle(req).pipe(
          catchError(e => {
            if (e.status === 403 || e.status === 401) {
              return this.simpleModalService.alert(getErrorMessage(e)).pipe(
                tap(_ => {
                  window.location.reload();
                }),
                switchMap(() => {
                  return of(e);
                })
              );
            }
            return of(e);
          })
        );
      })
    );
  }
}
