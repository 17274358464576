<footer class="common-footer footer">
  <div class="footer__button">
    <button
      *ngIf="hasCancel"
      type="button"
      class="button ha-btn ha-btn-secondary"
      (click)="handleCancel()"
    >
      {{ cancelText }}
    </button>
    <button
      *ngIf="showConfirm"
      type="button"
      class="button ha-btn ha-btn-spec"
      [disabled]="disabledConfirm"
      (click)="handleConfirm()"
    >
      {{ confirmText }}
    </button>
  </div>
</footer>
